import { moment } from '../../../../../Components'
import { BoxRomaneio, BoxDhPedido, HeaderMobile, HeaderDesk, BoxPagDesk, BoxPagMobile, BtnPagar } from './styles'

export default function CardHeader({ value }) {
  function renderPagamento() {
    if (value.tpPagamento == 'C') return <span style={{ margin: '0 auto' }}>Pago com cartão</span>
    else if (value.tpPagamento == 'P') return <span style={{ margin: '0 auto' }}>Pago com PIX</span>
    else if (value.tpPagamento == 'E')
      return (
        <>
          <span> Pagamento na entrega</span>
          <BtnPagar>Pagar agora</BtnPagar>
        </>
      )
  }

  return (
    <>
      <HeaderMobile>
        <BoxRomaneio>
          <span>Romaneio</span>
          <strong> {value.nrRomaneio ? value.nrRomaneio : 'Em definição'} </strong>
        </BoxRomaneio>
        <BoxDhPedido>
          <span>Pedido feito em:</span>
          <strong style={{ marginLeft: 'auto' }}>{moment(value.dhAprovacao).format('DD/MM/YYYY HH:mm')}</strong>
        </BoxDhPedido>
      </HeaderMobile>
      <BoxPagMobile>{renderPagamento()}</BoxPagMobile>

      <HeaderDesk>
        <BoxRomaneio>
          <span>Romaneio</span>
          <strong> {value.nrRomaneio ? value.nrRomaneio : 'Em definição'} </strong>
        </BoxRomaneio>
        <BoxPagDesk>{renderPagamento()}</BoxPagDesk>
        <BoxDhPedido>
          <span>Pedido feito em:</span>
          <strong style={{ marginLeft: 'auto' }}>{moment(value.dhAprovacao).format('DD/MM/YYYY HH:mm')}</strong>
        </BoxDhPedido>
      </HeaderDesk>
    </>
  )
}

import styled from 'styled-components'

export const TimeWrapper = styled.div`
  margin-bottom: 1rem;
  background: #fff;
`

export const Item = styled.div`
  position: relative;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  margin-left: 2rem;
  min-height: 4rem;

  border-left: 4px solid ${(props) => (props.pending ? '#d6d6d6' : '#28a745')};

  :last-child {
    padding-bottom: 2rem;
  }
`

export const ItemContent = styled.div`
  padding: 0.5rem 0.8rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;

  font-weight: 600;
  font-size: 17px;

  @media (max-width: 768px) {
    padding-left: 20px;
  }
  @media (min-width: 992px) {
    padding: 0;
    background-color: transparent;
  }

  span {
    display: block;
    color: #767676;
    font-size: 13px;
  }

  /* display: ${(props) => (props.showlarge ? 'none' : 'unset')}; */
`

export const BoxIcon = styled.div`
  position: absolute;
  left: -21px;
  width: 38px;
  height: 38px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  padding-top: 3px;

  border: 4px solid ${(props) => (props.pending ? '#d6d6d6' : '#28a745')};
`

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-bottom: 10px;
  padding: 4px;

  background-image: linear-gradient(to right, #684fa9 0%, #684fa9 19%, #7a63b6 60%, #9886c6 100%) !important;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
  border-width: 0;
  transition: all 0.2s;
  border-radius: 4px;
`

export const LblDescricao = styled.div`
  font-size: 14px;
  padding-left: 5px;
  margin-right: 10px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const LblQtde = styled.div`
  width: 70px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const BoxInfos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 5px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    align-items: center;
  }
`

export const LblPreco = styled.span`
  font-size: 0.8rem;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`
export const BtnDel = styled.button`
  margin: 0px 8px;
`

export const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  cursor: pointer;
  color: #fff;
  padding: 3px 0 3px 4px;
`

export const RowComp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding: 1px 0;
  :last-child {
    border-bottom: none; /* Remove a borda inferior do último item */
  }
`

export const BoxComp = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`

export const BoxIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 4px;
  padding: 4px;
  height: 36px;
  width: 36px;

  border: 1px solid #fff;
  border-radius: 50%;
`

////Componentes

export const CardComp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  cursor: pointer;
  color: #fff;
  margin: 8px 0 0;
  padding: 4px 0 0 12px;
  border-top: 1px solid #dee2e6 !important;
`

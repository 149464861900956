import { Card, CardBody, Col, Label, Row, FormGroup, Input } from 'reactstrap'
import { ItemHeader } from '../styles'
import { InputCnpjCpf } from '../../../../../Components'

export default function CardIdentificacao({ value, change, stepId }) {
  function onChange(ev) {
    const { name } = ev.target
    change({ value: { ...value, [name]: ev.target.value }, stepId })
  }

  return (
    <Card className="main-card mb-2" style={{ width: '100%' }}>
      <ItemHeader>Identificação</ItemHeader>
      <CardBody>
        {value && (
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label>CPF *</Label>
                <InputCnpjCpf onChange={onChange} value={value.clienteCpf} name="clienteCpf" id="clienteCpf" />
              </FormGroup>
            </Col>
            <Col md={9}>
              <FormGroup>
                <Label>Nome completo *</Label>
                <Input type="text" onChange={onChange} name="clienteNm" id="clienteNm" value={value.clienteNm} />
              </FormGroup>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  )
}

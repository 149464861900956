import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 12px;
  min-height: calc(100vh - 100px);
`

export const Main = styled.div`
  background-color: rgb(211, 218, 221);
  padding: 16px 2px;
  border-radius: 10px;
  margin: 0 auto 10px auto;
  max-width: 998px;

  @media (max-width: 576px) {
    margin: 0 5px 10px 5px;
    padding: 10px;
  }
`

export const CardMain = styled.div`
  background: rgb(255, 255, 255);
  padding: 16px 0;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const MainTimeLine = styled.div`
  width: 100%;

  @media (max-width: 576px) {
    width: 100%;
  }
`
export const MainContent = styled.div`
  width: 100%;
  padding: 0 10px;
  @media (max-width: 576px) {
    width: 100%;
  }
`

export const BoxTotais = styled.div``

export const ItemTotal = styled.div`
  width: 50%;
  font-size: 16px;
  margin: 2px 0 5px auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 768px) {
    max-width: 100% !important;
    width: 100% !important;
    align-items: flex-start;
  }
  > span:last-child {
    width: 120px;
    text-align: right;
  }
`

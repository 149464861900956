import React from 'react'

import { BoxIcon, Item, ItemContent, TimeWrapper } from './styles'
import { moment } from '../../../../../Components'
import { FaCheck } from 'react-icons/fa'
import { BsBoxes } from 'react-icons/bs'
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia'
import { GiDrippingTube } from 'react-icons/gi'
import { FiTruck } from 'react-icons/fi'

export default function TrackTimeline({ value }) {
  function renderStep(stepId, dhRegistro) {
    let isPending = dhRegistro ? 0 : 1

    return (
      <Item pending={isPending}>
        <BoxIcon pending={isPending}>
          {
            {
              1: <FaCheck color={isPending == 1 ? '#d6d6d6' : '#28a745'} size={24} />,
              2: <LiaFileInvoiceDollarSolid color={isPending == 1 ? '#d6d6d6' : '#28a745'} size={24} />,
              3: <GiDrippingTube color={isPending == 1 ? '#d6d6d6' : '#28a745'} size={24} />,
              4: <FiTruck color={isPending == 1 ? '#d6d6d6' : '#28a745'} size={24} />,
              5: <BsBoxes color={isPending == 1 ? '#d6d6d6' : '#28a745'} size={24} />,
            }[stepId]
          }
        </BoxIcon>
        <ItemContent>
          {
            {
              1: (
                <>
                  Pedido confirmado<span>{moment(dhRegistro).format('DD/MMM HH:mm')}</span>
                </>
              ),
              2: (
                <>
                  {dhRegistro ? (
                    <>
                      Pagamento aprovado<span>{moment(dhRegistro).format('DD/MMM HH:mm')}</span>
                    </>
                  ) : (
                    <>Aguardando aprovação financeira</>
                  )}
                </>
              ),
              3: (
                <>
                  {dhRegistro ? (
                    <>
                      Produção finalizada<span>{moment(dhRegistro).format('DD/MMM HH:mm')}</span>
                    </>
                  ) : (
                    <>Aguardando produção</>
                  )}
                </>
              ),
              4: (
                <>
                  {dhRegistro ? (
                    <>
                      Saiu para entrega<span>{moment(dhRegistro).format('DD/MMM HH:mm')}</span>
                    </>
                  ) : (
                    <>Aguardando expedição</>
                  )}
                </>
              ),
              5: (
                <>
                  {dhRegistro ? (
                    <>
                      Entrega em andamento<span>{moment(dhRegistro).format('DD/MMM HH:mm')}</span>
                    </>
                  ) : (
                    <>
                      Aguardando entrega
                      <span>Previsão: {moment(value.dtPrevEntrega).format('DD/MMM HH:mm')}</span>
                    </>
                  )}
                </>
              ),
            }[stepId]
          }
        </ItemContent>
      </Item>
    )
  }

  return (
    <TimeWrapper>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div>
            {renderStep(1, value.dhAprovacao)}
            {renderStep(2, value.authorizationDh)}
            {renderStep(3, '')}
            {renderStep(4, '')}
            {renderStep(5, '')}
          </div>
        </div>
      </div>
    </TimeWrapper>
  )
}

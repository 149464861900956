import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  width: 100%;
  padding: 4px;
  margin-bottom: 6px;
  background: linear-gradient(40deg, #fff, #684fa92e, #00ad72);
  border-radius: 4px;
`
export const MainBtns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
export const MainNotBtns = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const BoxBtns = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;

  svg {
    cursor: pointer;
    margin: 0 5px;
    font-size: 2rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    transition: background-color 0.15s ease-in-out;
    :hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  @media (max-width: 768px) {
    margin: 5px 0 0 auto;
  }
`
export const BoxInfosBtns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: 5px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    align-items: center;
  }
`
export const BoxInfosNotBtns = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  padding-top: 5px;
  margin-left: auto;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
  }
`

export const LblQtdeAdic = styled.span`
  font-size: 16px;
  padding-inline: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  color: #684fa9;
`
export const LblPreco = styled.span`
  font-size: 0.8rem;
  white-space: nowrap;
`
export const LblDescricao = styled.div`
  font-size: 14px;
  padding: 0 10px 0 5px;
  margin-right: auto;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
export const BtnDel = styled.button`
  margin: 0px 8px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`

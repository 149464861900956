import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'

export default function InputCreditNumber({ value, onChange, inputStyle, ...inputProps }) {
  const [state, setstate] = useState('')

  useEffect(() => {
    setstate(Mask(value))
    onChange({ target: { value: Mask(value), ...inputProps } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onInput = (ev) => {
    var valMasked = Mask(ev.target.value)
    setstate(valMasked)
    if (onChange) {
      onChange({ target: { value: valMasked, ...inputProps } })
    }
  }

  const Mask = (val) => {
    if (val == undefined) val = ''
    return val
      .replace(/\D/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
  }

  return <Input onChange={onInput} maxLength="19" type="text" value={state} {...inputProps} />
}

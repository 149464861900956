import { LiaCookieBiteSolid, LiaPillsSolid, LiaPrescriptionBottleSolid } from 'react-icons/lia'
import { BoxComp, BoxIcon, CardComp, RowComp, RowItem, Wrapper, BoxInfos, BtnDel, LblDescricao, LblPreco } from './styles'

import { FaCapsules, FaMortarPestle, FaPrescriptionBottle, FaPumpSoap, FaTrashAlt } from 'react-icons/fa'
import { FormatTextFirstUppercase, MaskReal } from '../../../../../helpers/util'
import { useState } from 'react'
import { FaBottleDroplet, FaHandHoldingDroplet } from 'react-icons/fa6'
import { MdOutlineViewHeadline } from 'react-icons/md'
import { RiInkBottleFill } from 'react-icons/ri'
import { GiEyedropper } from 'react-icons/gi'
import { CiPillsBottle1 } from 'react-icons/ci'
import { ImLibreoffice } from 'react-icons/im'
import { LuHexagon } from 'react-icons/lu'

export default function ItemOrdem({ index, value, onDelItem, showBtnDel = true }) {
  const [isOpen, setIsOpen] = useState(false)

  function renderTpFormula() {
    let cond1 = ['Shampoo', 'Creme', 'Gel', 'Floral', 'Xarope', 'Loção', 'Unidades', 'Outras', 'Pomada', 'Homeopatia']
    let cond2 = ['Envelope', 'Cápsula']
    if (cond1.includes(value.tpFormula)) {
      return (
        <LblDescricao>
          {value.tpFormula} | {value.qtde} {value.unMedida}
        </LblDescricao>
      )
    } else if (cond2.includes(value.tpFormula)) {
      return (
        <LblDescricao>
          {value?.fracaoDose ? value.qtde * value?.fracaoDose : value.qtde} {value.tpFormula}s
        </LblDescricao>
      )
    } else return <LblDescricao>{value.tpFormula}</LblDescricao>
  }

  function renderIcon() {
    if (value.tpFormula == 'Cápsula') return <FaCapsules size={22} />
    else if (value.tpFormula == 'Creme') return <FaPrescriptionBottle size={22} />
    else if (value.tpFormula == 'Loção') return <FaHandHoldingDroplet size={22} />
    else if (value.tpFormula == 'Shampoo') return <FaPumpSoap size={22} />
    else if (value.tpFormula == 'Outras') return <MdOutlineViewHeadline size={22} />
    else if (value.tpFormula == 'Unidades') return <RiInkBottleFill size={22} />
    else if (value.tpFormula == 'Homeopatia') return <FaMortarPestle size={22} />
    else if (value.tpFormula == 'Floral') return <GiEyedropper size={22} />
    else if (value.tpFormula == 'Gel') return <CiPillsBottle1 size={22} />
    else if (value.tpFormula == 'Envelope') return <ImLibreoffice size={22} />
    else if (value.tpFormula.includes('Biscoito')) return <LiaCookieBiteSolid size={22} />
    else if (value.tpFormula.includes('Pastilha')) return <LuHexagon size={22} />
    else if (value.tpFormula == 'Xarope') return <FaBottleDroplet size={22} />
    else if (value.tpFormula == 'Pomada') return <LiaPrescriptionBottleSolid size={22} />
    else return <LiaPillsSolid size={22} />
  }
  function renderFracao() {
    if (value.tpFormula === 'Cápsula' && value?.fracaoDose > 1) {
      return (
        <RowComp style={{ marginTop: '10px' }}>
          <LblDescricao>
            {value.fracaoDose} {value.tpFormula} = 1 dose
          </LblDescricao>
        </RowComp>
      )
    }
  }

  return (
    <Wrapper onClick={() => setIsOpen(!isOpen)}>
      <RowItem>
        <BoxComp>
          <RowComp>
            <LblDescricao>
              {index + 1} - Fórmula manipulada com {value.componentes[0].descricao}
            </LblDescricao>
          </RowComp>
          <RowComp>
            <BoxIcon>{renderIcon()}</BoxIcon>
            {renderTpFormula()}
          </RowComp>
        </BoxComp>

        <BoxInfos>
          <LblPreco>{MaskReal(value.valor)}</LblPreco>
          {showBtnDel && (
            <BtnDel className="btn btn-danger btn-sm" onClick={() => onDelItem(value.pedidoId)}>
              <FaTrashAlt size={16} />
            </BtnDel>
          )}
        </BoxInfos>
      </RowItem>

      {isOpen && (
        <CardComp>
          {value.componentes.map((d, cIndex) => (
            <RowComp key={`i${index}_comp${cIndex}`}>
              <LblDescricao>
                {FormatTextFirstUppercase(d.descricao)} {d.concentracaoQt} {d.concentracaoUn}
              </LblDescricao>
            </RowComp>
          ))}

          {renderFracao()}
        </CardComp>
      )}
    </Wrapper>
  )
}

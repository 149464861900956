import { useEffect, useState } from 'react'
import { BoxContent } from '../styles'
import { Main, WrapperTotal } from './styles'
import { Col, Row } from 'reactstrap'
import { getProductsWordPress } from '../../../../Service/ApiWordPress'
import { Bounce, moment, toast } from '../../../../Components'
import { FilialGetAll, OrcamentoUp, ParticipanteGetCep } from '../../../../Service/ApiService'
import { useResizeDetector } from 'react-resize-detector'

import Timeline from './Steps'
import CardIdentificacao from './CardIdentificacao'
import CardResumo from './CardResumo'
import CardTotal from './CardTotal'
import CardEntrega from './CardEntrega'
import CardPagamento from './CardPagamento'
import CardMsgFinal from './CardMsgFinal'
import CardSugeridos from './CardResumo/Sugestao'
import { CpfIsValid } from '../../../../helpers/util'

export default function Checkout({ value }) {
  const [stepId, setStepId] = useState(0)
  const [sugeridos, setSugeridos] = useState([])
  const [values, setValues] = useState()
  const [filiais, setFiliais] = useState()
  const { ref, width } = useResizeDetector()

  async function loadLojas() {
    const response = await FilialGetAll()
    let _filiais = []
    let _return = ''
    if (response) {
      response.forEach((d) => {
        if (d.nome.length > 0 && d.tpLogistica === 'R') {
          _filiais.push({ value: d.filialId, label: `${d.codigo} - ${d.nome}`, endereco: d.endereco })

          if (value.tpEntrega === 'R' && value.filialIdRet == d.filialId) _return = { value: d.filialId, label: `${d.codigo} - ${d.nome}`, endereco: d.endereco }
        }
      })
    }
    setFiliais(_filiais)
    return _return
  }

  async function loadSugeridos() {
    const response = await getProductsWordPress(['7434543032', '7360899092', '7210733082', '2581146505'])
    if (response) setSugeridos([...response, ...response])
  }
  async function loadOrcamento() {
    let _value = { ...value }
    _value.filRetirada = await loadLojas()

    setValues(_value)

    loadSugeridos()

    //   let response = await OrcamentoGetByKey(id)

    //   if (response) setValues({ ...response, tpPagamento: 'C' })
    //   else toast('Ocorreu um problema ao realizar a consulta', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
  }
  useEffect(() => {
    loadOrcamento()
  }, [value])

  useEffect(() => {
    if (values) calculateVlTotal()
  }, [values?.pedidos, values?.itensAdicionais, values?.itensAdicionais?.length, values?.tpEntrega])

  const calculateVlTotal = () => {
    let total = 0.0

    values.itens?.forEach((d) => {
      if (!values.pedidos.filter((f) => f.pedidoId == d.pedidoId)[0].isExcluido) total += d.valor * d.qtdPote
    })

    values.itensAdicionais?.forEach((d) => {
      total += d.price * d.qtde
    })

    if (values?.tpEntrega == 'E') total = total + values.vlFrete

    setValues({ ...values, vlTotal: total })
  }

  async function ChangeItem(ev) {
    setValues(ev.value)

    if (!validStepNext(ev.stepId, ev.value)) return

    if (ev.stepId == 3) {
      if ((await onSubmit({ ...ev.value, situacao: 10 })) == false) return
    } else {
      await onSubmit(ev.value)
    }

    setStepId(ev.stepId)
  }

  function onUpSugestao(ev) {
    let _itensAdicionais = []
    if (ev.op === 'Add') {
      let _item = values.itensAdicionais?.filter((f) => f.id == ev.value.id)
      if (_item?.length > 0) {
        values.itensAdicionais.forEach((d) => {
          if (d.id == ev.value.id) _itensAdicionais.push({ ...d, qtde: d.qtde + 1 })
          else _itensAdicionais.push(d)
        })
      } else {
        _itensAdicionais = values.itensAdicionais ? values.itensAdicionais : []
        _itensAdicionais.push({ ...ev.value, qtde: 1 })
      }
    } else if (ev.op === 'Del') {
      _itensAdicionais = values.itensAdicionais.filter((f) => f.id != ev.value.id)
    } else if (ev.op === 'Dec') {
      values.itensAdicionais.forEach((d) => {
        if (d.id == ev.value.id) {
          if (d.qtde - 1 > 0) _itensAdicionais.push({ ...d, qtde: d.qtde - 1 })
        } else _itensAdicionais.push(d)
      })
    }

    setValues({ ...values, itensAdicionais: _itensAdicionais })
  }
  function onDelItem(pedidoId) {
    let _pedidos = []

    values.pedidos.forEach((d) => {
      if (d.pedidoId == pedidoId) _pedidos.push({ ...d, isExcluido: true })
      else _pedidos.push(d)
    })

    let itensAdicionais = _pedidos.filter((f) => f.isExcluido == false)?.length == 0 ? [] : value.itensAdicionais

    setValues({ ...values, itensAdicionais, pedidos: _pedidos })
  }

  async function onSubmit(value) {
    try {
      let _values = { ...value }
      if (value.tpEntrega == 'R' && value.filRetirada?.value) _values.filialIdRet = value.filRetirada?.value
      if (value.tpEntrega == 'R') _values.vlFrete = 0

      const response = await OrcamentoUp(_values)
      if (response?.result) return true
      else {
        if (!response || response === undefined)
          toast('Ocorreu um problema ao salvar o registro! Tente novamente!', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        else toast(response.message, { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
    } catch (errr) {
      console.error('onSubmit', errr)
    }
  }

  function validStepNext(stepUp, value) {
    if (stepUp < stepId || stepUp == stepId) return true

    //valida os itens do pedido
    if (stepId === 0) {
      //Resumo do pedido
      if (value.pedidos.filter((f) => f.isExcluido == false)?.length === 0) {
        toast('Para avançar, precisa exister pelo menos uma fórmula no pedido', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
    } else if (stepId === 1) {
      //Entrega
      if (!value.tpEntrega) {
        toast('Selecione o tipo de entrega', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }

      if (!value.clienteCpf || !value.clienteNm) {
        toast('Informe os dados de identificação', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
      if (!CpfIsValid(value.clienteCpf)) {
        toast('O CPF informado é inválido', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }

      if (value.tpEntrega == 'R' && !value.filRetirada?.value) {
        toast('Para retirar, selecione a loja desejada', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
      if (value.tpEntrega == 'E' && (!value.endCep || !value.endNumero)) {
        toast('Informe o endereço para entrega', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
    } else if (stepId === 2) {
      //Pagamento
      if (!value.tpPagamento) {
        toast('Selecione o tipo de pagamento', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }

      //  if (value.tpPagamento == 'C' && (!value.endCep || !value.endNumero)) {
      //    toast('Informe o endereço para entrega', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
      //    return false
      //  }
    }

    return true
  }

  function onStepNext(ev) {
    if (validStepNext(ev, values)) setStepId(ev)
  }

  return (
    <>
      <Timeline stepId={stepId} onStepNext={onStepNext} />

      <Main>
        {values && (
          <BoxContent ref={ref}>
            <Row style={{ width: '100%' }}>
              {stepId === 3 ? (
                <CardMsgFinal stepId={stepId} value={values} change={ChangeItem} />
              ) : (
                <>
                  <Col md={8}>
                    {
                      {
                        0: (
                          <CardResumo
                            sugeridos={sugeridos}
                            stepId={stepId}
                            value={values}
                            change={ChangeItem}
                            onUpSugestao={onUpSugestao}
                            onDelItem={onDelItem}
                            isSugeridos={width < 768 ? false : true}
                          />
                        ),
                        1: (
                          <>
                            <CardIdentificacao stepId={stepId} value={values} change={ChangeItem} />
                            <CardEntrega stepId={stepId} value={values} change={ChangeItem} filiais={filiais} />
                          </>
                        ),
                        2: <CardPagamento stepId={stepId} value={values} change={ChangeItem} />,
                      }[stepId]
                    }
                  </Col>
                  <WrapperTotal md={4}>
                    <CardTotal stepId={stepId} value={values} change={ChangeItem} />
                  </WrapperTotal>

                  {stepId == 0 && width < 768 ? sugeridos?.length > 0 && <CardSugeridos records={sugeridos} onAdd={(ev) => onUpSugestao(ev)} /> : <></>}
                </>
              )}
            </Row>
          </BoxContent>
        )}
      </Main>
    </>
  )
}

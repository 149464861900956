import { CardTitle, Col, Row } from 'reactstrap'
import { Wrapper, CardMain, MainTimeLine, MainContent, Main, BoxTotais, ItemTotal, BoxPagamento } from './styles'
import TrackTimeline from './Timeline'
import { MaskReal } from '../../../../helpers/util'
import ItemOrdem from '../Components/ItemOrdem'
import CardEntrega from './CardEntrega'
import CardHeader from './CardHeader'
import ItemAdic from '../Components/ItemAdic'

export default function Tracking({ value }) {
  return (
    <Wrapper>
      <Main>
        <CardHeader value={value} />

        <Row>
          <Col md={5} className="mt-1">
            <CardMain>
              <MainTimeLine>
                <TrackTimeline value={value} />
              </MainTimeLine>
            </CardMain>
          </Col>
          <Col md={7} className="mt-1">
            <Row>
              <CardEntrega value={value} />

              <Col md={12} style={{ padding: 0 }} className="mt-1">
                <CardMain>
                  <MainContent>
                    <CardTitle>
                      <span>Itens do pedido</span>
                    </CardTitle>
                    <div className="tb-report position-relative table-responsive">
                      {value?.itens?.map((d, index) => (
                        <ItemOrdem key={`p_${d.pedidoId}`} index={index} value={d} showBtnDel={false} />
                      ))}

                      {value.itensAdicionais?.map((d, index) => (
                        <ItemAdic key={`a_${d.id}`} value={d} index={value?.itens.length + index} showBtns={false} />
                      ))}
                    </div>

                    <BoxTotais>
                      <ItemTotal>
                        <span>Subtotal:</span> <span style={{ fontWeight: '600' }}>{MaskReal(value.vlTotal)}</span>
                      </ItemTotal>
                      <ItemTotal>
                        <span>Frete:</span> <span style={{ fontWeight: '600' }}>{MaskReal(value.vlFrete)}</span>
                      </ItemTotal>
                      <ItemTotal>
                        <span>Valor total:</span> <span style={{ color: '#684fa9', fontWeight: '600' }}>{MaskReal(value.vlTotal)}</span>
                      </ItemTotal>
                    </BoxTotais>
                  </MainContent>
                </CardMain>
              </Col>
            </Row>
          </Col>
        </Row>
      </Main>
    </Wrapper>
  )
}

import styled from 'styled-components'

export const CartItems = styled.div`
  display: flex;
  flex-direction: column;
`
export const LblVazio = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: red;
  padding: 10px 0;
`

import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'

function InputComponent({ value, onChange, onBlur, ...inputProps }) {
  const [state, setstate] = useState(value)

  useEffect(() => {
    setstate(cpfMask(value))
  }, [value])

  const onInput = (ev) => {
    var valTratado = cpfMask(ev.target.value)
    setstate(valTratado)

    if (onChange) {
      onChange({
        target: { value: valTratado.replace(/\D/g, ''), ...inputProps },
      })
    }
  }

  const cpfMask = (value) => {
    if (value === undefined) return
    if (value === null) value = ''
    if (value.length <= 11 || (value.length === 14 && value.includes('.'))) {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    } else {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
    }
  }

  function onBlurlocal() {
    if (onBlur) onBlur()
  }

  return <Input onChange={onInput} onBlur={onBlurlocal} type="text" inputMode="numeric" value={state} {...inputProps} />
}

export default InputComponent

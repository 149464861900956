import { Card, CardBody, Col, Label, Row, FormGroup, Input } from 'reactstrap'
import { BtnNext, ItemHeader } from '../styles'
import { MsgPay, OptMain, PayItem } from './styles'
import { FaMoneyBillWave, FaRegCheckCircle, FaTruck } from 'react-icons/fa'
import { BsCreditCard } from 'react-icons/bs'
import { Bounce, Select, toast } from '../../../../../Components'
import { useState } from 'react'
import InputCreditNumber from './InputCreditNumber'
import InputCardValidity from './InputCardValidity'

const initValues = { cardNumber: '', cardValidity: '', cardCode: '', cardName: '' }

export default function CardPagamento({ value, change, stepId }) {
  const [values, setValues] = useState(initValues)
  const parcelas = Array.from({ length: 6 }, (_, i) => {
    const numParcelas = i + 1
    const valorParcela = calcularParcelas(numParcelas)
    return {
      value: numParcelas,
      label: `${numParcelas}x de R$ ${valorParcela} sem juros`,
    }
  })
  function calcularParcelas(numParcelas) {
    return (value.vlTotal / numParcelas).toFixed(2)
  }

  function onSubmit() {
    if (value.tpPagamento === 'C') {
      if (!values.cardNumber || !values.cardValidity || !values.cardCode || !values.cardName) {
        toast('Para cartão,  preencha todos os campos!', { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
        return false
      }
    }
    change({ value: { ...value, situacao: 10 }, stepId: 3 })
  }

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  return (
    <Card className="main-card mb-3" style={{ width: '100%' }}>
      <ItemHeader>Pagamento</ItemHeader>
      <CardBody>
        <OptMain>
          <PayItem selected={value.tpPagamento == 'C'} name="tpPagamento" value="C" onClick={() => change({ value: { ...value, tpPagamento: 'C' }, stepId })}>
            <BsCreditCard size={32} />
            <span>Cartão de Crédito</span>
          </PayItem>

          <PayItem selected={value.tpPagamento == 'P'} name="tpPagamento" value="P" onClick={() => change({ value: { ...value, tpPagamento: 'P' }, stepId })}>
            <FaMoneyBillWave size={32} />
            <span>Pix</span>
          </PayItem>

          <PayItem selected={value.tpPagamento == 'E'} name="tpPagamento" value="E" onClick={() => change({ value: { ...value, tpPagamento: 'E' }, stepId })}>
            <FaTruck size={32} />
            <span>Pagar na entrega</span>
          </PayItem>
        </OptMain>

        {value.tpPagamento === 'C' && (
          <Row>
            <Col md={7}>
              <FormGroup>
                <Label>Número do Cartão: *</Label>
                <InputCreditNumber name="cardNumber" id="cardNumber" value={values.cardNumber} onChange={onChange} />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup>
                <Label>Validade: *</Label>
                <InputCardValidity name="cardValidity" id="cardValidity" value={values.cardValidity} placeholder="MM/AA" onChange={onChange} />
              </FormGroup>
            </Col>

            <Col md={2}>
              <FormGroup>
                <Label>CVV: *</Label>
                <Input type="text" name="cardCode" id="cardCode" value={values.cardCode} maxLength={3} placeholder="CVV" onChange={onChange} />
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <Label>Nome impresso no cartão: *</Label>
                <Input type="text" name="cardName" id="cardName" value={values.cardName} maxLength={64} onChange={onChange} />
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <Label>Parcelamento: *</Label>
                <Select
                  placeholder="Selecione o número de parcelas"
                  name="parcelamento"
                  id="parcelamento"
                  noOptionsMessage={() => 'Nenhuma opção disponível'}
                  value={values.parcelamento}
                  options={parcelas}
                  onChange={onChangeCat}
                />
              </FormGroup>
            </Col>
          </Row>
        )}

        {value.tpPagamento === 'P' && (
          <Row>
            <MsgPay className="ml-3 mr-3">
              <span>O link é válido por 1 hora, click em Finalizar minha compra para gerar. </span>
              <hr />
              <span>Seu pedido será processado após a confirmação do pagamento!</span>
            </MsgPay>
          </Row>
        )}

        {value.tpPagamento === 'E' && value.vlTotal > value.regraSinalPag.vlMinimo && (
          <Row>
            <MsgPay className="ml-3 mr-3">Para compras acima de R$ 500,00, é necessario o pagamento de {value.regraSinalPag.percentual}% adiantado.</MsgPay>
          </Row>
        )}

        <BtnNext showlarge={true} onClick={onSubmit}>
          <FaRegCheckCircle size={22} className="mr-2" /> <span>Finalizar minha compra</span>
        </BtnNext>
      </CardBody>
    </Card>
  )
}

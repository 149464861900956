import styled from 'styled-components'

export const HeaderMobile = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 0 10px;

  @media (max-width: 576px) {
    display: flex;
  }
`

export const HeaderDesk = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 10px 10px;

  @media (min-width: 576px) {
    display: flex;
  }
`
export const BoxRomaneio = styled.div`
  display: flex;
  flex-direction: column;
`
export const BoxDhPedido = styled.div`
  display: flex;
  flex-direction: column;
`

export const BoxPagDesk = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 4px;

  background-image: linear-gradient(to right, #684fa9 0%, #684fa9 19%, #7a63b6 60%, #9886c6 100%) !important;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
  border-width: 0;
  transition: all 0.2s;
  border-radius: 4px;
  color: #fff;

  > span {
    padding: 4px 10px;
  }
  @media (min-width: 577px) {
    display: flex !important;
  }
`

export const BoxPagMobile = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 4px;
  margin-bottom: 10px;

  background-image: linear-gradient(to right, #684fa9 0%, #684fa9 19%, #7a63b6 60%, #9886c6 100%) !important;
  box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03), 0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);
  border-width: 0;
  transition: all 0.2s;
  border-radius: 4px;
  color: #fff;
  > span {
    padding: 0 10px;
  }

  @media (max-width: 576px) {
    display: flex;
  }
`

export const BtnPagar = styled.button`
  color: #fff;
  background-color: #3ac47d;
  border-color: #3ac47d;

  margin-left: 10px;
  position: relative;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;

  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.6rem 1rem;
  line-height: 1.5;
  border-radius: 4px;

  :hover {
    color: #fff;
    background-color: #31a66a;
    border-color: #2e9d64;
  }
`

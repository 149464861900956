import { Card, CardBody } from 'reactstrap'
import { MaskReal, normaliseValue } from '../../../../../helpers/util'
import { BoxDtEntrega, ItemTotal, TextTotal, TotalContainer } from './styles'
import { BsInfoCircle } from 'react-icons/bs'
import moment from 'moment'
import { BtnNext } from '../styles'

export default function CardTotal({ value, change, stepId }) {
  return (
    <Card className="main-card mb-1 pl-2 pr-2" style={{ width: '100%' }}>
      <CardBody>
        <TotalContainer>
          <ItemTotal style={{ fontWeight: '600' }}>
            <span>Subtotal:</span> <span>R$ {normaliseValue(value.vlTotal)}</span>
          </ItemTotal>
          <ItemTotal>
            Desconto: <span>-</span>
          </ItemTotal>
          {value.tpEntrega == 'E' ? (
            <ItemTotal>
              Entrega: <span style={{ color: 'rgb(14, 178, 83)', fontWeight: '600' }}>{value.vlFrete > 0 ? MaskReal(value.vlFrete) : 'Grátis'}</span>
            </ItemTotal>
          ) : (
            <ItemTotal>
              Retirada: <span style={{ color: 'rgb(14, 178, 83)', fontWeight: '600' }}> Grátis</span>
            </ItemTotal>
          )}
          <BoxDtEntrega>
            <span>Receba seu pedido até o dia {moment(value.dtPrevEntrega).format('DD/MMM')}</span>
            {moment().format('HH') < 17 && (
              <span>
                <BsInfoCircle className="mr-2" />
                Válido para pedidos concluídos hoje até 17:00
              </span>
            )}
          </BoxDtEntrega>
          <TextTotal>
            <p>
              <span>Total: </span>
            </p>
            <p>
              <span> R$ </span>
              {normaliseValue(value.vlTotal)}
            </p>
          </TextTotal>
        </TotalContainer>

        <BtnNext showlarge={false} onClick={() => change({ value, stepId: stepId + 1 })}>
          {
            {
              0: <span>Ir para dados de entrega</span>,
              1: <span>Ir para pagamento</span>,
              2: <span>Finalizar minha compra</span>,
            }[stepId]
          }
        </BtnNext>
      </CardBody>
    </Card>
  )
}

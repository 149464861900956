import { FaTrashAlt } from 'react-icons/fa'
import { MdAdd, MdRemove } from 'react-icons/md'
import { Wrapper, MainBtns, BoxBtns, BoxInfosNotBtns, BtnDel, LblDescricao, LblPreco, LblQtdeAdic, BoxInfosBtns, MainNotBtns } from './styles'
import { MaskReal } from '../../../../../helpers/util'

export default function ItemAdic({ value, onUpSugestao, showBtns = true }) {
  return (
    <Wrapper>
      {showBtns ? (
        <>
          <MainBtns>
            <LblDescricao>{value.name}</LblDescricao>

            <BoxBtns>
              <MdRemove onClick={() => onUpSugestao({ value, op: 'Dec' })} style={{ color: '#fff' }} />
              <LblQtdeAdic>{value.qtde}</LblQtdeAdic>
              <MdAdd onClick={() => onUpSugestao({ value, op: 'Add' })} style={{ color: '#fff' }} />
            </BoxBtns>
          </MainBtns>

          <BoxInfosBtns>
            <LblPreco>{MaskReal(value.price)}</LblPreco>
            <BtnDel className="btn btn-danger btn-sm" onClick={() => onUpSugestao({ value, op: 'Del' })}>
              <FaTrashAlt />
            </BtnDel>
          </BoxInfosBtns>
        </>
      ) : (
        <>
          <MainNotBtns>
            <LblDescricao>{value.name}</LblDescricao>

            <BoxInfosNotBtns>
              <LblQtdeAdic>{value.qtde}</LblQtdeAdic>
              <LblPreco>{MaskReal(value.price)}</LblPreco>
            </BoxInfosNotBtns>
          </MainNotBtns>
        </>
      )}
    </Wrapper>
  )
}

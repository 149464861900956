import { CardTitle, Col } from 'reactstrap'
import { CardMain, MainContent } from '../styles'

export default function CardEntrega({ value }) {
  return (
    <Col md={12} style={{ padding: 0 }}>
      <CardMain>
        <MainContent>
          <CardTitle>
            <span>{value.tpEntrega == 'R' ? 'Retirada' : 'Entrega'} </span>
          </CardTitle>
          {value.tpEntrega == 'R' ? (
            <>
              <span>
                Unidade: <strong>{value.entrega?.unidade}</strong>
              </span>
              <span className="ml-2">
                Endereço: <strong>{value.entrega?.endereco}</strong>
              </span>
            </>
          ) : (
            <span>
              Endereço: <strong>{value.entrega.endereco}</strong>
            </span>
          )}
        </MainContent>
      </CardMain>
    </Col>
  )
}

import { Card, CardBody } from 'reactstrap'
import { BtnNext, ItemHeader } from '../styles'
import { CartItems, LblVazio } from './styles'
import ItensSugeridos from './Sugestao'
import { GrMapLocation } from 'react-icons/gr'
import { useEffect, useState } from 'react'
import ItemOrdem from '../../Components/ItemOrdem'
import ItemAdic from '../../Components/ItemAdic'

export default function CardSugeridos({ value, change, onUpSugestao, sugeridos, onDelItem, isSugeridos }) {
  const [pedItens, setPedItens] = useState([])

  useEffect(() => {
    if (value) getItens()
  }, [value])

  function getItens() {
    let result = []
    value.pedidos.forEach((ped) => {
      if (!ped.isExcluido) {
        var pedValue = value?.itens.filter((f) => f.pedidoId == ped.pedidoId)
        result.push(pedValue[0])
      }
    })
    setPedItens(result)
  }

  return (
    <>
      <Card className="main-card mb-2" style={{ width: '100%' }}>
        <ItemHeader>Itens do pedido</ItemHeader>
        <CardBody>
          {pedItens?.length > 0 || value.itensAdicionais?.length > 0 ? (
            <CartItems>
              {pedItens?.map((d, index) => (
                <ItemOrdem key={`p_${d.pedidoId}`} index={index} value={d} onDelItem={onDelItem} />
              ))}

              {value.itensAdicionais?.map((d) => (
                <ItemAdic key={d.id} value={d} onUpSugestao={onUpSugestao} />
              ))}
            </CartItems>
          ) : (
            <LblVazio>Carrinho de compras vazio!</LblVazio>
          )}
          <BtnNext showlarge={true} onClick={() => change({ value, stepId: 1 })}>
            <GrMapLocation size={18} className="mr-2" /> <span>Ir para dados de entrega</span>
          </BtnNext>
        </CardBody>
      </Card>

      {sugeridos?.length > 0 && isSugeridos ? <ItensSugeridos records={sugeridos} onAdd={(ev) => onUpSugestao(ev)} /> : <></>}
    </>
  )
}

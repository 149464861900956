import { Card, CardBody, Col, Label, Row, FormGroup, Input } from 'reactstrap'
import { BtnNext, ItemHeader } from '../styles'
import { FaTruck } from 'react-icons/fa'
import { FaHouseFlag } from 'react-icons/fa6'
import { Bounce, InputCep, Select, toast } from '../../../../../Components'
import { OptItem, OptMain } from './styles'
import { HiOutlineCreditCard } from 'react-icons/hi'
import { ParticipanteGetCep } from '../../../../../Service/ApiService'

export default function CardEntrega({ value, change, stepId, filiais }) {
  function onChange(ev) {
    const { name, value: newValue } = ev.target

    if (name == 'endCep' && newValue?.length === 9) {
      if (newValue == value.endCep) return
      loadCep(newValue)
      return
    }
    change({ value: { ...value, [name]: newValue }, stepId })
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    change({ value: { ...value, [name]: newValue }, stepId })
  }

  async function loadCep(endCep) {
    var result = await ParticipanteGetCep(endCep)

    if (result.msgResultado === 'ok') {
      change({ value: { ...value, endCep, endLogradouro: result.logradouro, endBairro: result.bairro, endCidade: result.localidade, endEstado: result.uf, endComp: '' }, stepId })
    } else {
      toast('CEP não encontrado', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
    }
  }

  return (
    <Card className="main-card mb-2" style={{ width: '100%' }}>
      <ItemHeader>Entrega</ItemHeader>
      <CardBody>
        {value && (
          <Row>
            <Col xs={12}>
              <OptMain>
                <OptItem selected={value.tpEntrega == 'R'} name="tpEntrega" value="R" onClick={() => change({ value: { ...value, tpEntrega: 'R' }, stepId })}>
                  <FaHouseFlag size={26} />
                  <span>Retirar na loja</span>
                </OptItem>

                <OptItem selected={value.tpEntrega == 'E'} name="tpEntrega" value="E" onClick={() => change({ value: { ...value, tpEntrega: 'E' }, stepId })}>
                  <FaTruck size={26} />
                  <span>Entregar</span>
                </OptItem>
              </OptMain>

              {value.tpEntrega === 'R' && (
                <Row>
                  <Col md={8} style={{ margin: '0 auto' }}>
                    <FormGroup>
                      <Label>Selecione a unidade para a retirar *</Label>
                      <Select
                        placeholder="Selecione"
                        onChange={onChangeCat}
                        noOptionsMessage={() => 'Nenhuma opção disponível'}
                        value={value.filRetirada}
                        isClearable
                        name="filRetirada"
                        className="basic-single-select"
                        classNamePrefix="select"
                        options={filiais}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Endereço da loja</Label>
                      <Input type="text" name="filialEnd" id="filialEnd" value={value?.filRetirada?.endereco} disabled />
                    </FormGroup>
                  </Col>
                </Row>
              )}

              {value.tpEntrega === 'E' && (
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Cep *</Label>
                      <InputCep onChange={onChange} value={value.endCep} name="endCep" id="endCep" />
                    </FormGroup>
                  </Col>
                  <Col md={7}>
                    <FormGroup>
                      <Label>Rua </Label>
                      <Input type="text" disabled value={value.endLogradouro} name="endLogradouro" id="endLogradouro" />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Numero </Label>
                      <Input type="text" onChange={onChange} value={value.endNumero} name="endNumero" id="endNumero" />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Complemento </Label>
                      <Input type="text" onChange={onChange} value={value.endComp} name="endComp" id="endComp" />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup>
                      <Label>Bairro </Label>
                      <Input type="text" disabled value={value.endBairro} name="endBairro" id="endBairro" />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Cidade/Estado </Label>
                      <Input type="text" disabled value={value.endCidade} name="endCidade" id="endCidade" />
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </Col>

            <BtnNext showlarge={true} onClick={() => change({ value: { ...value }, stepId: 2 })}>
              <HiOutlineCreditCard size={22} className="mr-2" /> <span>Ir para pagamento</span>
            </BtnNext>
          </Row>
        )}
      </CardBody>
    </Card>
  )
}

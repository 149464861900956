import React, { useEffect, useState } from 'react'
import Footer from './AppFooter'
import { Bounce, LoadingCard, toast, useParams } from '../../../Components'
import { BoxContent, BoxLoading, Wrapper } from './styles'
import CardHeader from './CardHeader'
import Checkout from './Checkout'
import Tracking from './Tracking'
import { OrcamentoGetByKey } from '../../../Service/ApiService'

export default function FormCadastro() {
  const { id } = useParams()
  const [step, setStep] = useState({ id: 1, msg: '' })
  const [values, setValues] = useState()

  async function loadOrcamento() {
    let response = await OrcamentoGetByKey(id)

    if (response) {
      if (!response.result) setStep({ id: 4, msg: response.message })
      else {
        let _values = response.orcamento
        setValues(_values)

        if (_values.situacao == 0) setStep({ id: 2, msg: '' })
        else setStep({ id: 3, msg: '' })
      }
    } else toast('Ocorreu um problema ao realizar a consulta', { type: 'error', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' })
  }

  useEffect(() => {
    if (id) loadOrcamento()
  }, [id])

  return (
    <Wrapper>
      <CardHeader />
      {
        {
          1: (
            <BoxLoading>
              <LoadingCard isVisible={true} placeholder="Aguarde ..." />
            </BoxLoading>
          ),
          2: <Checkout value={values} />,
          3: <Tracking value={values} />,
          4: (
            <BoxLoading>
              <BoxContent>
                <h1 style={{ textAlign: 'Center', width: '100%', color: 'red' }}> {step.msg}</h1>
              </BoxContent>
            </BoxLoading>
          ),
        }[step.id]
      }

      <Footer />
    </Wrapper>
  )
}
